'use client';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { createTheme, responsiveFontSizes, Theme, ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { useServerInsertedHTML } from 'next/navigation';
import { PropsWithChildren, useState } from 'react';
import type {} from '@mui/x-data-grid/themeAugmentation';

import { darkThemeOptions, lightThemeOptions } from '@/theme/theme';
import { Theme_enum } from '@/types/exported.types';

type Props = {
  themePreference: Theme_enum;
};

type RegistryProps = {
  options: { key: string; prepend?: boolean };
  theme: Theme;
} & PropsWithChildren;

const ThemeRegistry: React.FC<RegistryProps> = (props) => {
  const { options, children, theme } = props;

  const [{ cache, flush }] = useState(() => {
    const cache = createCache(options);
    cache.compat = true;
    const prevInsert = cache.insert;
    let inserted: string[] = [];
    cache.insert = (...args) => {
      const serialized = args[1];
      if (cache.inserted[serialized.name] === undefined) {
        inserted.push(serialized.name);
      }
      return prevInsert(...args);
    };
    const flush = () => {
      const prevInserted = inserted;
      inserted = [];
      return prevInserted;
    };
    return { cache, flush };
  });

  useServerInsertedHTML(() => {
    const names = flush();
    if (names.length === 0) {
      return null;
    }
    let styles = '';
    for (const name of names) {
      styles += cache.inserted[name];
    }
    return (
      <style
        key={cache.key}
        data-emotion={`${cache.key} ${names.join(' ')}`}
        dangerouslySetInnerHTML={{
          __html: options.prepend ? `@layer emotion {${styles}}` : styles,
        }}
      />
    );
  });

  return (
    <CacheProvider value={cache}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </CacheProvider>
  );
};

export const StyleWrapper: React.FC<Props & PropsWithChildren> = ({ children, themePreference }) => {
  const theme = createTheme({
    cssVariables: true,
    ...(themePreference === 'dark' ? darkThemeOptions : lightThemeOptions),
    mixins: {
      MuiDataGrid: {
        // Pinned columns sections
        pinnedBackground: themePreference === 'dark' ? '#393939' : '#fff',
        // Headers, and top & bottom fixed rows
        containerBackground: themePreference === 'dark' ? '#393939' : '#fff',
      },
    },
  });
  const themeWithResponsiveFontSizes = responsiveFontSizes(theme);
  return (
    <ThemeRegistry options={{ key: 'mui' }} theme={themeWithResponsiveFontSizes}>
      {children}
    </ThemeRegistry>
  );
};
