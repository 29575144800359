import React from 'react';

export type CrumbProps = {
  embedId: string;
};

export const YoutubeEmbed: React.FC<CrumbProps> = ({ embedId }) => {
  return (
    <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0 }}>
      <iframe
        width="853"
        height="480"
        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', borderRadius: 16 }}
        src={`https://www.youtube.com/embed/${embedId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  );
};
