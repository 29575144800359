import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Link, Typography } from '@mui/material';
import { t } from 'i18next';

import { supportEmailAddress } from '@/utils/config';
import { YoutubeEmbed } from './YouTubeEmbed';

type Props = {
  open: boolean;
  onClose: () => void;
};

export const HelpDialog: React.FC<Props> = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t('needHelp')}</DialogTitle>
      <DialogContent>
        <Typography>
          {t('needHelpDescription') + ' '}
          <Link href={'mailto:' + supportEmailAddress}>{supportEmailAddress}</Link>
        </Typography>
        <Box>
          <Box mt={4} mb={2}>
            <Typography>{t('maybeMartinCanHelp')}</Typography>
          </Box>
          {['pmbmFK7jZDw', 'MobKc-chnS4', 'FnbFZmshBmQ', 'Wvzy0SMzs5o'].map((id) => (
            <Box key={id} mb={2}>
              <YoutubeEmbed embedId={id} />
            </Box>
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('cancel')}</Button>
      </DialogActions>
    </Dialog>
  );
};
